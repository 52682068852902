// Libs
import React, { ReactChild, useEffect } from 'react';

// import { QueryResult } from '@apollo/client';
import { RouteComponentProps, Router } from '@reach/router';

// Hooks

import Loading from '@components/Loading';

import { EditProfileSharingPermission } from '@domain/profile';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';
// import { useFetchIndividualPrograms } from '@repositories/profile/hooks';

// Components

// Types
import { localizedNavigate } from '@utils/localized-navigate';

// import { ProgramDefinition } from '@typings/graphql';
// import {
//   Program,
//    FetchEditProgramsQuery
// } from '@typings/operations';

import { DynamicData } from '@domui-domain/type';
import { useFetchCountries } from '@domui-hooks/useFetchCountries';
import { useFetchIndividualPrograms } from '@domui-hooks/useFetchIndividualPrograms';
import { useFetchProgramList } from '@domui-hooks/useFetchProgramList';

import { useTranslation } from '@external/react-i18next';

export type EditProgramsSharingPermissions = {
  program: EditProfileSharingPermission;
};

type ChildProps = {
  individualId: string;
  programs: DynamicData;
  individualPrograms: DynamicData;
  sharingPermissionsExtended: string;
  countries: DynamicData;
  refetch: (dominoId: string) => Promise<void>;
};

interface Props extends RouteComponentProps {
  individualId?: string;
  render: (props: ChildProps) => ReactChild;
}

const EditPrograms: React.FC<Props> = ({ render, individualId }) => {
  const { t } = useTranslation();
  const { addError } = useNotifications();

  const {
    data: individualPrograms,
    loading: programLoading,
    error,
    fetchIndividualPrograms,
    refetch,
  } = useFetchIndividualPrograms();
  useErrorHandling(
    t('view-profile.query.error', 'An error occurred.'),
    error !== undefined,
    'profile-view.error'
  );

  const {
    data: programs,
    loading: listLoading,
    error: listError,
    fetchProgramList,
  } = useFetchProgramList();
  useErrorHandling(
    t('view-profile.query.error', 'An error occurred.'),
    listError !== undefined,
    'profile-view.error'
  );

  const {
    data: countries,
    loading: contriesLoading,
    fetchCountries,
  } = useFetchCountries();

  useEffect(() => {
    function getPrograms() {
      if (individualId) {
        try {
          fetchIndividualPrograms(individualId);
          fetchProgramList();
          fetchCountries();
        } catch (error) {
          addError((error as Error).message, { id: 'form.error' });
        }
      }
    }

    getPrograms();
  }, [individualId]);
  const individualProgramList: object[] = individualPrograms?.programs;

  // const {
  //   data,
  //   loading,
  //   error,
  //   //   refetch
  // } = useFetchPrograms(individualId || '');
  if (!individualId) {
    localizedNavigate(`/domui/profile/${individualId}`);
    return <Loading />;
  }
  if (programLoading || listLoading || contriesLoading) {
    return <Loading />;
  }
  if (
    error ||
    !individualPrograms
    // || !data.individual
  ) {
    return <p>Error</p>;
  }

  return (
    <Router>
      {render({
        individualId,
        programs,
        individualPrograms: individualProgramList,
        sharingPermissionsExtended:
          individualPrograms.sharingPermission || null,
        countries,
        refetch,
      })}
    </Router>
  );
};

export default EditPrograms;
