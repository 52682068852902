import { useState } from 'react';

import Individual from '../utils/workflow/individual';

import { DomuiError, DynamicData } from '@domui-domain/type';

// Custom hook to handle API calls
export const useFetchIndividualPrograms = () => {
  const [data, setData] = useState<DynamicData>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const fetchIndividualPrograms = async (dominoId: string) => {
    // Clear error before new request
    try {
      if (dominoId) {
        setLoading(true);
        setError(undefined);
        const wfService = new Individual();
        const response = await wfService.getIndividualPrograms(dominoId);
        const fetchData = {
          programs: [],
          sharingPermission: null,
        };
        const data = response?.status === 404 ? fetchData : response;
        setData(data);
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const refetch = async (dominoId: string) => {
    fetchIndividualPrograms(dominoId);
  };
  return { data, loading, error, fetchIndividualPrograms, refetch };
};
