import React, { useState } from 'react';

// import { QueryResult } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import { Select } from '@components/Forms/Select';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Title from '@components/Title';
import VerifiedTag from '@components/VerifiedTag';

import { buildProgramValidationSchema } from '../../validationSchema';
import EditProgramFields from '../components/EditProgramFields';
import Info from '../components/Info';

import { FormValues } from '@domain/profile/types';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import { mapFormValuesToCreateProgramInput } from '@domui-domain/profile/mappers';
import { DynamicData, RecordList } from '@domui-domain/type';
import { useCreateProgram } from '@domui-hooks/useCreateProgram';
import {
  getProgramDefinitionById,
  selectFieldsFromProgramDefinition,
} from '@domui-use-cases/profile/helpers/';

import { useTranslation } from '@external/react-i18next';
import { OptionTypeBase, ValueType } from '@external/react-select';

interface Props extends RouteComponentProps {
  individualId: string;
  programs: DynamicData;
  countries: DynamicData;
  refetch: (dominoId: string) => Promise<void>;
}

const AddProgram: React.FC<Props> = ({
  individualId,
  programs,
  countries,
  refetch,
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();
  const [selectedProgramId, setSelectedProgramId] = useState();
  const { createProgram, loading } = useCreateProgram();

  const programDefinition = getProgramDefinitionById(
    programs as DynamicData[],
    selectedProgramId
  ) as RecordList;

  const generateInitialValues = (
    programDefinition: DynamicData[]
  ): FormValues => {
    const getInitialValue = (fieldType: String) => {
      if (fieldType === 'Date') {
        return new Date();
      }
      return '';
    };

    return selectFieldsFromProgramDefinition(programDefinition).reduce(
      (initialValues, currentValue) => {
        return {
          ...initialValues,
          [currentValue?.fieldId]: getInitialValue(currentValue?.type),
        };
      },
      {}
    ) as FormValues;
  };

  const handleSubmit = async (values: FormValues) => {
    await createProgram({
      individualId,
      programId: programDefinition?.programId,
      fields: mapFormValuesToCreateProgramInput(programDefinition, values),
    });

    addSuccess(
      t('edit-background.form.success', 'Program added successfully.'),
      {
        id: 'form.success',
      }
    );
    await refetch(individualId);
    localizedNavigate(`/domui/profile/${individualId}/edit-programs`);
  };

  const handleCancel = () => {
    localizedNavigate(`/domui/profile/${individualId}/edit-programs`);
  };

  return (
    <OneColumn className="mb-20">
      <LinkPrevious
        path={`/domui/profile/${individualId}/edit-programs`}
        label={t('edit-profile.programs.back-link', 'Programs')}
      />
      <Title>{t('edit-profile.programs.title', 'Add Rotary Program')}</Title>
      <Info />
      <Select
        id="programId"
        name="programId"
        className="tablet:w-1/2"
        label={t('edit-profile.programs.program-title', 'Program Title')}
        options={programs?.map((programDefinition: DynamicData) => ({
          label: programDefinition?.title,
          value: programDefinition?.programId,
        }))}
        onChange={(option: ValueType<OptionTypeBase>) =>
          setSelectedProgramId((option as OptionTypeBase).value)
        }
      />
      {programDefinition ? (
        <Formik
          onSubmit={handleSubmit}
          initialValues={generateInitialValues(
            programDefinition as DynamicData[]
          )}
          validationSchema={buildProgramValidationSchema(t, programDefinition)}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <EditProgramFields
                  programDefinition={programDefinition as DynamicData}
                  countries={countries}
                />
                <div className="my-8 text-sm text-gray-500">
                  <VerifiedTag pending />
                  {t(
                    'edit-profile.programs.edit.verification',
                    'This information will be sent to Rotary for verification.'
                  )}
                </div>
                <div className="grid grid-cols-1 gap-2 content-between tablet:w-1/2">
                  <Button disabled={loading || isSubmitting}>
                    {t(
                      'edit-profile.programs.save-button-label',
                      'Save Changes'
                    )}
                  </Button>
                  <Button secondary type="button" clickHandler={handleCancel}>
                    {t('edit-profile.programs.cancel-button-label', 'Cancel')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : null}
    </OneColumn>
  );
};

export default AddProgram;
