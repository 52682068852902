import React from 'react';

// import { QueryResult } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';
import VerifiedTag from '@components/VerifiedTag';

import EditProgramFields from './components/EditProgramFields';
import Info from './components/Info';
import { buildProgramValidationSchema } from '../validationSchema';

import { FormValues } from '@domain/profile/types';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import {
  mapFormValuesToUpdateProgramInput,
  mapProgramDataToFormValues,
} from '@domui-domain/profile/mappers';
import { DynamicData, RecordList } from '@domui-domain/type';
import { useUpdateProgram } from '@domui-hooks/useUpdateProgram';
import {
  getIndividualProgramById,
  getProgramDefinitionById,
} from '@domui-use-cases/profile/helpers/';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  individualId: string;
  individualPrograms: DynamicData;
  programs: DynamicData;
  countries: DynamicData;
  individualProgramId?: string;
  refetch: (dominoId: string) => Promise<void>;
}

const EditIndividualProgram: React.FC<Props> = ({
  individualId,
  individualPrograms,
  programs,
  countries,
  individualProgramId,
  refetch,
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();
  const { updateProgram, loading, error } = useUpdateProgram();

  useErrorHandling(
    error?.description
      ? error.description
      : t('edit-background.form.error', 'An error occurred.'),
    !!error,
    'edit-background.form.error'
  );

  if (!individualProgramId) {
    localizedNavigate(`/domui/profile/${individualId}`);
    return <Loading />;
  }

  const individualProgram = getIndividualProgramById(
    individualPrograms as DynamicData[],
    individualProgramId
  );

  const programDefinition = getProgramDefinitionById(
    programs as DynamicData[],
    individualProgram?.programId
  ) as RecordList;

  const handleSubmit = async (values: FormValues) => {
    const isUpdated = await updateProgram({
      individualId,
      programId: programDefinition?.programId,
      individualProgramId,
      fields: mapFormValuesToUpdateProgramInput(programDefinition, values),
    });
    if (!isUpdated) {
      return false;
    }
    await refetch(individualId);
    addSuccess(
      t('edit-background.form.success', 'Program updated successfully.'),
      {
        id: 'form.success',
      }
    );
    localizedNavigate(`/domui/profile/${individualId}/edit-programs`);
    return false;
  };

  const handleCancel = () => {
    localizedNavigate(`/domui/profile/${individualId}/edit-programs`);
  };

  return (
    <OneColumn>
      <LinkPrevious
        path={`/domui/profile/${individualId}/edit-programs`}
        label={t('edit-profile.programs.back-link', 'Programs')}
      />
      <Title>Edit Rotary Program</Title>
      <Info />
      <span className="font-bold">
        {t('edit-profile.programs.program-title', 'Program Title')}
      </span>
      <p className="mt-2">{programDefinition?.title}</p>
      <Formik
        onSubmit={handleSubmit}
        initialValues={mapProgramDataToFormValues(
          programDefinition as RecordList,
          individualProgram as RecordList
        )}
        // initialValues={{}}
        validationSchema={buildProgramValidationSchema(
          t,
          programDefinition as RecordList
        )}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <EditProgramFields
                programDefinition={programDefinition}
                countries={countries}
              />
              <p className="my-8 text-sm text-gray-500">
                <VerifiedTag pending={!individualProgram?.isVerified} />
                {t(
                  'edit-profile.programs.edit.verification',
                  'This information will be sent to Rotary for verification.'
                )}
              </p>
              <Button disabled={loading || isSubmitting}>
                {t('edit-profile.programs.save-button-label', 'Save Changes')}
              </Button>
              <Button secondary type="button" clickHandler={handleCancel}>
                {t('edit-profile.programs.cancel-button-label', 'Cancel')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </OneColumn>
  );
};

export default EditIndividualProgram;
