import { selectFieldsFromProgramDefinition } from '.';

import { RecordList } from '@domui-domain/type';

export const getFieldDefinitionById = (
  programDefinition: RecordList,
  fieldId: string
): RecordList =>
  selectFieldsFromProgramDefinition(programDefinition).filter(
    f => f?.fieldId === fieldId
  )[0] as RecordList;
