import { useState } from 'react';

import Individual from '../utils/workflow/individual';

import { DomuiError, RecordList } from '@domui-domain/type';

type createProgramArgs = {
  individualId: string;
  programId: string;
  fields: RecordList;
};

// Custom hook to handle API calls
export const useCreateProgram = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<DomuiError>(null);

  const createProgram = async ({
    individualId,
    programId,
    fields,
  }: createProgramArgs) => {
    let updateStatus = false;
    setLoading(true);
    setError(null); // Clear error before new request

    try {
      const wfService = new Individual();
      await wfService.createProgram(individualId, programId, fields);
      updateStatus = true;
    } catch (err) {
      setError(err as DomuiError);
      updateStatus = false;
    } finally {
      setLoading(false);
    }
    return updateStatus;
  };

  return { loading, error, createProgram };
};
