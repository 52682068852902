// import { getFieldDefinitionById } from '@domui-use-cases/profile';
import moment from 'moment';

import { FieldType, RecordList } from '@domui-domain/type';
import { getFieldDefinitionById } from '@domui-use-cases/profile/helpers/getFieldDefinitionById';

export const mapFormValuesToCreateProgramInput = (
  programDefinition: RecordList,
  values: RecordList
): RecordList[] => {
  return Object.keys(values).map(key => {
    const fieldDefinition = getFieldDefinitionById(programDefinition, key);
    const isDate = fieldDefinition.type === FieldType.Date;
    const mapping =
      fieldDefinition.mapping === 'hostCountry' ||
      fieldDefinition.mapping === 'sponsorCountry'
        ? `${fieldDefinition.mapping}Code`
        : fieldDefinition.mapping;
    return {
      fieldId: key,
      mapping,
      value: isDate
        ? moment(values[key]).format('YYYY-MM-DD')
        : values[key] || null,
    };
  });
};
