import React from 'react';

import Select from '@components/Formik/Select';
import TextField from '@components/Formik/TextField';
import { InputDate } from '@components/Forms/InputDate';

import { DynamicData } from '@domui-domain/type';

import { FieldType } from '@typings/graphql';

type Props = DynamicData;

const ProgramField: React.FC<Props> = field => {
  const labelValue = field?.isRequired ? `${field?.label}*` : field?.label;
  if (field?.type === FieldType.Options) {
    return (
      <Select
        name={field?.fieldId}
        label={labelValue}
        additionalClasses="tablet:w-1/2"
        options={
          field?.countries
            ? field?.countries.map(
                (f: { countryName: string; id: string }) => ({
                  value: f.id,
                  label: f.countryName,
                })
              )
            : []
        }
      />
    );
  }
  if (field?.type === FieldType.Date) {
    return (
      <InputDate
        name={field?.fieldId}
        label={labelValue}
        id={field?.fieldId}
        className="tablet:w-1/2"
        placeholderDateFormat="DD MMM YYYY"
        selectedDateFormat="dd MMM yyyy"
      />
    );
  }
  if (field?.type === FieldType.String || field?.type === FieldType.Number) {
    return (
      <div className="tablet:w-1/2">
        <TextField name={field?.fieldId} label={labelValue} />
      </div>
    );
  }
  return null;
};

export default ProgramField;
