import { FieldType, RecordList } from '@domui-domain/type';
import { selectFieldsFromProgramDefinition } from '@domui-use-cases/profile/helpers/';

import { FormValues } from '../types';

export const mapProgramDataToFormValues = (
  programDefinition: RecordList,
  individualProgram: RecordList
): FormValues => {
  const getInitialValue = (fieldType: FieldType, field: RecordList) => {
    if (fieldType === FieldType.Date) {
      if (!field) {
        return new Date();
      }

      return new Date(field.fieldValue);
    }
    if (!field) {
      return '';
    }

    return field.fieldValue;
  };

  return selectFieldsFromProgramDefinition(programDefinition).reduce(
    (initialValues, currentValue) => {
      return {
        ...initialValues,
        [currentValue?.fieldId]: getInitialValue(
          currentValue?.type,
          individualProgram.displayFields.filter(
            (f: RecordList) => f.fieldName === currentValue?.mapping
          )[0]
        ),
      };
    },
    {}
  ) as FormValues;
};
