import { useState } from 'react';

import Individual from '../utils/workflow/individual';

import { DomuiError, RecordList } from '@domui-domain/type';

type updateProgramArgs = {
  individualId: string;
  programId: string;
  individualProgramId: string;
  fields: RecordList;
};

// Custom hook to handle API calls
export const useUpdateProgram = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<DomuiError>(null);

  const updateProgram = async ({
    individualId,
    programId,
    individualProgramId,
    fields,
  }: updateProgramArgs) => {
    let updateStatus = false;
    setLoading(true);
    setError(null); // Clear error before new request

    try {
      const wfService = new Individual();
      await wfService.updateProgram(
        individualId,
        programId,
        individualProgramId,
        fields
      );
      updateStatus = true;
    } catch (err) {
      setError(err as DomuiError);
      updateStatus = false;
    } finally {
      setLoading(false);
    }
    return updateStatus;
  };

  return { loading, error, updateProgram };
};
