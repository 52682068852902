import { useState } from 'react';

import Individual from '../utils/workflow/individual';

import { DomuiError } from '@domui-domain/type';

// Custom hook to handle API calls
export const useDeleteProgram = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const deleteProgram = async (individualProgramId: string) => {
    // Clear error before new request
    try {
      if (individualProgramId) {
        setLoading(true);
        setError(undefined);
        const wfService = new Individual();
        await wfService.deleteProgram(individualProgramId);
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, deleteProgram };
};
